import i18n from "@/plugins/i18n.js";

/**
 * get the key of an enum by the enumObject and the id of a resource's enumId (or typeId)
 */
export const getEnumKeyByValue = (enumObject, enumValue) => {
    return Object.keys(enumObject).find(
        (key) => enumObject[key].toString() === enumValue.toString()
    );
};

/**
 * get the translated value of an enum and a value
 */
export const getTranslatedEnumValue = (enumObject, enumValue, translationPrefix) => {
    return i18n.t(
        `${translationPrefix}.${getEnumKeyByValue(enumObject, enumValue)}`
    );
};

/**
 * get a array with an enum's options with it's translated key and it's value
 */
export const getTranslatedEnumOptions = (enumObject, translationPrefix) => {
    return Object.keys(enumObject).map((key) => ({
        text: i18n.t(`${translationPrefix}.${key}`),
        value: enumObject[key],
    }));
};
