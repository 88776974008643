<template>
  <KSelect :items="items" v-bind="$attrs" v-on="$listeners" />
</template>

<script lang="ts">
import Vue from "vue";
import KSelect from "@/components/crud/fields/KSelect.vue";
import { getTranslatedEnumOptions } from "@/application/util/enum.js";
import { UnitLoad } from "@/enums/delivery.js";

export default Vue.extend({
  name: "UnitLoadSelect",
  components: { KSelect },
  data: () => ({
    items: getTranslatedEnumOptions(UnitLoad, "delivery.enums.unitLoad"),
  }),
});
</script>
