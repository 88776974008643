<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="delivery.form.fields">
        <v-col cols="12" lg="6" md="8" offset-lg="3" offset-md="2" offset-sm="1" sm="10">
          <v-subheader class="pa-0">{{ $t(`delivery.form.title${isUpdateForm ? "Update" : "Add"}`) }}</v-subheader>
          <v-card>
            <v-card-text>
              <v-row>
                <v-col md="6">
                  <KTextField field="reference" :value="values.reference" disabled/>
                  <KTextField field="clientName" :value="values.clientName" disabled/>
                  <KTextField
                      field="plannedDate"
                      v-model="values.plannedDate"
                      required
                      type="date"
                  />
                  <UnitLoadSelect
                      field="unitLoad"
                      v-model="values.unitLoad"
                      required
                  />
                  <template
                      v-if="
                      !!values.unitLoad && values.unitLoad !== UnitLoad.Colli
                    "
                  >
                    <KTextField
                        :field="`unitLoadInfo.${values.unitLoad}`"
                        type="number"
                        required
                        v-model="values.unitLoadInfo"
                    />
                  </template>

                  <DeliveryOrderStatusSingleAutocomplete field="status" v-model="values.status"/>
                  <KCheckbox field="isPreadviced" v-model="values.isPreadviced"/>
                  <KCheckbox field="isNotAccordingToConditions" v-model="values.isNotAccordingToConditions"/>
                  <UserAutocomplete field="assignedUser" v-model="values.assignedUser"/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-subheader class="mt-6 pa-0">{{ $t("delivery.form.comments") }}</v-subheader>
          <v-card>
            <v-card-text>
              <KTextarea field="comment" v-model="values.comment" hide-details/>
            </v-card-text>
          </v-card>
          <v-card v-for="(comment, key) in values.comments" :key="key" class="mt-6">
            <v-card-text>
              <span class="font-weight-bold">{{ comment.name}}</span> ({{ formatDate(comment.createdAt)}}):<br />
              {{ comment.comment }}
            </v-card-text>
          </v-card>
        </v-col>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import Form from "@/components/vuetifyResource/VuetifyResourceBaseForm.vue";
import KTextField from "@/components/crud/fields/KTextField.vue";
import dayjs from "@/plugins/dayjs.js";
import UserAutocomplete from "@/components/autocompletes/UserAutocomplete.vue";
import UnitLoadSelect from "@/components/autocompletes/UnitLoadSelect.vue";
import DeliveryOrderStatusSingleAutocomplete from "@/components/autocompletes/DeliveryOrderStatusSingleAutocomplete.vue";
import KTextarea from "@/components/crud/fields/KTextarea.vue";
import KCheckbox from "@/components/crud/fields/KCheckbox.vue";
import { UnitLoad } from "@/enums/delivery";

export default {
  name: "PurchaseOrderForm",
  computed: {
    UnitLoad() {
      return UnitLoad
    }
  },
  extends: Form,
  components: {
    KCheckbox,
    KTextarea,
    UserAutocomplete,
    UnitLoadSelect,
    DeliveryOrderStatusSingleAutocomplete,
    KTextField,
    KFieldGroup,
  },
  data: () => ({
    values: {
      assignedUser: null,
      clientName: "",
      reference: "",
      plannedDate: null,
      hasMetaData: false,
      isPreadviced: false,
      unitLoad: null,
      unitLoadInfo: undefined,
      isNotAccordingToConditions: false,
      id: null,
      status: null,
      comments: [],
      comment: ""
    },
  }),
  methods: {
    formatDate(date) {
      return dayjs(date).format("DD MMMM YYYY");
    },
  }
};
</script>
