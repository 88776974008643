<template>
  <KPaginatedAutocomplete :field="field" :paginator="autocomplete" v-bind="$attrs" v-on="$listeners"/>
</template>

<script>
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete';
import { statusAutoComplete } from "@/api/endpoints/purchaseOrder";
export default {
  name: 'DeliveryOrderStatusSingleAutocomplete',
  components: { KPaginatedAutocomplete },
  props: {
    field: {
      type: String,
    },
  },
  methods: {
    autocomplete(...args) {
      return statusAutoComplete(...args);
    },
  },
};
</script>
